import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Rating from 'react-rating';
import Link from 'next/link';
// react slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// API helper
import { apiHelper } from '../../../helper/apiHelper';
import { jwtDecode } from "../../../helper/jwtHelper";
// Html Parser
import parse from "html-react-parser";
// scss
import styles from "./hirewriter.module.scss";
// Image
import DummyProfile from '../../../public/dummy-profile.webp';
import {DEFAULT_LOCALES_LIST} from "../../../helper/constantHelper";
import Image from "next/image";
import Script from "next/script";
import { getCurrencyId } from "../../../helper/utilHelper";
import { Container } from "react-bootstrap";
import dynamic from "next/dynamic";
const Img = dynamic(() => import('../../Common/image'));
const HireWriter = (props) => {

  const router = useRouter();
  const {currLocale,linkLocale} = props;
  const storeRightData = (key, value) => {
    const rightData = localStorage.getItem("orderRight");

    let item = {};
    if (rightData && rightData !== '') {
      item = JSON.parse(rightData);

      item[key] = value;
      localStorage.setItem("orderRight", JSON.stringify(item));
    }
  }

  const redirectUrl = async (user_name) => {

    const order_token = localStorage.getItem("orderToken");
    let decodeOrder = {};
    if (order_token && order_token !== '') {
      decodeOrder = jwtDecode(order_token);
    }

    storeRightData('topWriter', '+20%');
    storeRightData('writerId', user_name);

    const formData = new FormData();

    formData.append("service", decodeOrder.service ? decodeOrder.service : 3);
    formData.append("paper", decodeOrder.paper ? decodeOrder.paper : 1);
    formData.append("page", decodeOrder.page ? decodeOrder.page : 1);
    formData.append("deadline", decodeOrder.deadline ? decodeOrder.deadline : '');
    formData.append("duration", decodeOrder.duration ? decodeOrder.duration : '');
    formData.append("deadlineLable", decodeOrder.deadlineLable ? decodeOrder.deadlineLable : '');
    formData.append("coupon_code", decodeOrder.coupon_code ? decodeOrder.coupon_code : '');
    formData.append("preferred_writer", 'my_previous_writer');
    formData.append("writer_id", user_name);
    formData.append("currency_id", getCurrencyId(currLocale));

    apiHelper("setOrderV1", "POST", formData, null)
      .then((res) => {
        if (res.data.status) {
          const token = res.data.data.order_token;
          localStorage.setItem("orderToken", token);
          router.push('/order', '/order', { locale: linkLocale });
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  const imgNotFound = (e) => {
    e.target.src = DummyProfile;
  }

  const removeAriaHiddenFromSlider = () => {
    const slides = document.getElementsByClassName("slick-slide");

    for (const element of slides) {
      if (element.hasAttribute("aria-hidden") && element.getAttribute("aria-hidden") === 'true') {
        element.removeAttribute("aria-hidden");
      }
    }
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    loop: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    afterChange: () => {
      removeAriaHiddenFromSlider()
    }
  };

  let personSchema = '';
  if (props.topWriters && props.topWriters.length > 0) {
    personSchema = `[
      ${props.topWriters.map(element =>
      `{
          "@context": "https://schema.org/",
          "@type": "Person",
          "url": "${process.env.hostBaseUrl}${DEFAULT_LOCALES_LIST.includes(currLocale) ? "/" : `/${currLocale}`}/writers-profile/${element.user_name && (element.user_name).toLowerCase()}",
          "name": "${element.writer_name ? element.writer_name : element.user_name}",
          "description": "${element.description ? (element.description).replace(/"/g, '&quot;') : ''}",
          "nationality": {
            "@type": "Country",
            "name": "${element.location}"
          }
        }`
    )}
    ]`;
  }

  useEffect(() => {
    window.addEventListener("load", removeAriaHiddenFromSlider);

    return () => {
      window.removeEventListener("load", removeAriaHiddenFromSlider);
    }
  }, []);

  return <>
    {personSchema !== '' ? <Script id="person-schema" type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: personSchema }} strategy="beforeInteractive" /> : null}
    <section className={`${styles.hireWriter} pb-0`}>
      <Container>
        <div className="text-center">
          <h2 className="section-title">{props.title ? parse((props.title).toString()) : ''}</h2>
          <div className="desc">{props.desc}</div>
        </div>
        <div className={styles.writerWrpper} id="hire-write-slider">
          {props.topWriters && props.topWriters.length > 0 ?
            <Slider {...settings}>
              {props.topWriters.map((info) =>
                <div className={styles.writerBox} key={info.writer_name}>
                  <div className={styles.writerImg}>
                    <Image
                      src={info.profile_pic ? info.profile_pic : "./dummy-profile.webp"}
                      alt={info.writer_name ? info.writer_name : info.user_name}
                      className="img-fluid"
                      onError={(e) => imgNotFound(e)}
                      width={250}
                      height={250}
                      title={"<" + info.writer_name + "> Top Writer"}
                    />
                  </div>
                  <div className={styles.writerDetail}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.name}>
                        <Link href={`/writers-profile/${(info.user_name).toLowerCase()}`}
                              locale={linkLocale} prefetch={false}>
                          {info.writer_name ? info.writer_name : info.user_name}
                        </Link>
                      </div>
                      <div className={styles.star}>
                        <Rating
                          readonly={true}
                          initialRating={info.review_count}
                          emptySymbol={<Img src="/empty.svg" className="icon" alt="ratingImg" width={18} height={18} />}
                          fullSymbol={<Img src="/full.svg" className="icon" alt="ratingImg" width={18} height={18} />}
                        />
                      </div>
                    </div>
                    <ul className={`${styles.subDetail} d-flex flex-wrap`}>
                      <li className="d-flex">
                        <Image src="/excellence.svg" title="Excellence" alt="excellence" width={21} height={21} />
                        <span>{info.completed_order} orders</span>
                      </li>
                      <li className="d-flex">
                        <Image src="/success-badge.svg" title="Success" alt="success-badge" width={21} height={21} />
                        <span>{info.success_rate} Success rate</span>
                      </li>
                      <li className="d-flex">
                        <Image src="/reviews.svg" title="Reviews" width={21} height={21} alt="reviews" />
                        <span>{info.review_count} Reviews</span>
                      </li>
                      <li className="d-flex">
                        <Image src="/degree.svg" title="Degree" width={21} height={21} alt="degree" />
                        <span>Master’s Degree</span>
                      </li>
                    </ul>
                    <div className="text-center">
                      <button onClick={() => redirectUrl(info.user_name)}
                              className="btn secondary-btn inline-button-click w-100">Hire Writer</button>
                    </div>
                  </div>
                </div>)}
            </Slider> :
            <div className="noReview-found">No writer found</div>}
          {props.topWriters && props.topWriters.length > 0 &&
            <div className="text-center">
              <Link
                href={"/top-writers"}
                locale={linkLocale}
                prefetch={false}
                className={`btn outlineSecondaryBtn ${styles.outlineSecondaryBtn}`}>
                View All Writers
              </Link>
            </div>}
        </div>
      </Container>
    </section>
  </>;
}

export default HireWriter;